.pricing-rules-manager-wrapper{
  padding:10px;
  .pricing-rules-developed-rules-wrapper {
    width: 100%;

    .pricing-rules-developed-rules-wrapper-header {
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .pricing-rules-developed-rules-wrapper-header-title {
        font-size: 24px;
        align-self: flex-start;
      }
    }

    .pricing-rules-developed-rules-wrapper-content {
      .pricing-rules-developed-rules-wrapper-content-table {
        overflow-y: auto;

        .pricing-rules-developed-rules-wrapper-content-pagination {
          padding: 10px;
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        }
      }
    }
  }
}



.pricing-rules-developed-rules-modal-fields-wrapper {
  width: 800px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .pricing-rules-developed-rules-modal-fields-wrapper-dimensions {
    .pricing-rules-developed-rules-modal-fields-wrapper-dimensions-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .pricing-rules-developed-rules-modal-fields-wrapper-dimension {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pricing-rules-developed-rules-modal-fields-wrapper-dimension-libelle {
        width: 60%;
      }


      .pricing-rules-developed-rules-modal-fields-wrapper-dimension-diminutif {
        width: 15%;
      }

      .pricing-rules-developed-rules-modal-fields-wrapper-dimension-unit {
        width: 15%;
      }

      .pricing-rules-developed-rules-modal-fields-wrapper-dimension-point {
        width: 40%;
      }

      .pricing-rules-developed-rules-modal-fields-wrapper-dimension-pourcentage {
        width: 40%;
      }

    }
  }
}

.pricing-rules-developed-rules-modal-graph{
  width:100%;
  height:200px;
}

.pricing-rules-rules-wrapper {
  width: 100%;
  margin-bottom: 24px;

  .pricing-rules-rules-wrapper-header {
    padding: 10px;
    display: flex;
    justify-content: space-between;

    .pricing-rules-rules-wrapper-header-title {
      font-size: 24px;
      align-self: flex-start;
    }
  }

  .pricing-rules-rules-wrapper-graphs-container {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;

    .pricing-rules-rules-wrapper-graph-container {
      width: 50%;
      height: 300px;
      margin-bottom: 60px;

      .pricing-rules-rules-wrapper-graph-container-header {

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        span {

        }
      }

    }
  }
}

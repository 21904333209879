@import 'src/App';

.clients-wrapper {
  height: calc(100% - 20px);
  display: flex;

  .clients-menu {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    width: 20%;
    height: calc(100% - 20px);
    overflow: auto;

    .clients-menu-autocomplete {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .clients-menu-autocomplete-fields-wrapper {
        padding: 4px;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        flex-direction: column;
      }

      .clients-menu-autocomplete-fields-wrapper:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    .clients-menu-title {
      font-size: 20px;
      margin-bottom: 30px;
    }

    .clients-menu-category-wrapper-button {
      padding-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 60px;

      .clients-menu-category-wrapper-button-icon {
        margin-right: 5px;
      }

      .clients-menu-category-wrapper-button-count {
        margin-left: 10px;
        background-color: #1079FC;
        color: #FFFFFF;
        border-radius: 22px;
        min-width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 3px;
        padding-right: 3px;
      }
    }

    .clients-menu-category-wrapper-button:hover {
      background: $hover-item;
    }
  }

  .clients-wrapper-content {
    padding: 10px;
    width: 80%;
    overflow: auto;
    height: 100%;

    .clients-client-details-wrapper {
      padding: 10px;

      .clients-client-details-wrapper-title {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        font-size: 18px;
        align-self: flex-start;
      }

      .clients-client-details-wrapper-content {
        line-height: 20px;
      }

      .client-details-left-line-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2px;
        margin-bottom: 2px;
      }

      .client-details-left-line-content {
        width: 350px;
        padding-right: 12px;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }


    .clients-contacts-wrapper {
      padding: 10px;
      margin-top: 20px;

      .clients-contacts-wrapper-title {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;
        font-size: 18px;
        align-self: flex-start;
      }
    }

    .clients-contacts-wrapper-table {
      table {
        thead {
          tr {
            th {
              font-weight: bold !important;
            }
          }
        }
      }
    }

    .clients-wrapper-content-no-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      color: #CCCCCC;

      img {
        width: 30%;
        margin-bottom: 20px;
      }
    }
  }
}

.clients-notes-wrapper {
  padding: 10px;
  margin-top: 20px;

  .clients-notes-wrapper-title {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    font-size: 18px;
    align-self: flex-start;

  }

  .clients-notes-wrapper-content {
    .clients-notes-wrapper-content-note {
      padding: 10px;

      .clients-notes-wrapper-content-note-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom:8px;
      }

      .clients-notes-wrapper-content-note-content {
        span {
          margin-right: 10px;
        }

        margin-bottom: 10px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.client-operations-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .client-operations-wrapper-wrapper{
    padding:10px;
    height: 100%;
    .client-operations-wrapper-header {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }

    .client-operations-wrapper-content {
      height:calc(100% - 60px);
      .client-operations-wrapper-content-table {
        height:calc(100% - 42px);
        table {
          thead {
            tr {
              th {
                font-weight: bold !important;
              }
            }
          }
        }

        .client-operations-wrapper-content-table-operation-ref-wrapper {
          cursor: pointer;
          background-color: #1079FC;
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
          padding: 4px;
          margin-top: -2px;
          margin-bottom: -2px;
          font-weight: bold;
          border-radius: 4px;
          color: #FFFFFF;
          width: fit-content;
        }
      }

      .client-operations-wrapper-content-pagination {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }

      .client-operations-wrapper-content-table-actions {
        min-width: 160px;
      }
    }
  }
  .client-operations-wrapper-content-loader-wrapper{
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    align-items: center;
  }

}

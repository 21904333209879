@import '../../../App';

.unique-article-search-wrapper {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .unique-article-search-wrapper-menu {


    .unique-article-search-wrapper-menu-title {
      background-color: #FAFAFA;
      padding: 10px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px $light-gray;
      font-size: 24px;

      div {
        font-size: 18px;
      }
    }

    .unique-article-search-wrapper-menu-text-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .unique-article-search-wrapper-menu-text-fields-text-field {
        padding: 10px;
        display: flex;
        align-items: center;
      }
    }
  }

  .unique-article-search-wrapper-results {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .unique-article-search-wrapper-results-title {
      background-color: #FAFAFA;
      padding: 10px;
      display: flex;
      align-items: center;
      border-bottom: solid 1px $light-gray;
      font-size: 24px;

      div {
        font-size: 18px;
      }
    }

    .unique-article-search-wrapper-menu-body {
      height: 100%;
      display: flex;
      flex-direction: column;

      .unique-article-search-wrapper-header {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 24px;

        .unique-article-search-wrapper-header-counts {
          display: flex;
          flex-direction: column;
        }

        .unique-article-search-wrapper-header-filter-sort {
          .unique-article-search-wrapper-header-sort-input {
            width: 300px;
          }

          .unique-article-search-wrapper-header-filter-input {
            margin-left: 10px;
            width: 300px;
          }

          button {
            border: solid 1px rgb(196, 196, 196);
            margin-left: 10px;
          }
        }
      }

      .unique-article-search-wrapper-content {
        padding: 10px;
        padding-top: 0;
        height: calc(100% - 110px);

        .unique-article-search-wrapper-content-table {
          height: calc(100% - 46px);
          overflow-y: auto;

          th {
            font-weight: bold;
          }

          tr:hover {
            background-color: rgba(0, 0, 0, 0.12);
          }

          tr {
            cursor: pointer;
          }
        }

        .unique-article-search-content-pagination {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;
        }
      }

      .unique-article-search-wrapper-empty-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .unique-article-search-wrapper-empty-content-undraw {
          width: 25%;
        }

        p {
          //color: #CCCCCC;
        }
      }
    }
  }
}
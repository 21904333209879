.email-modal-wrapper{
  position:relative;
  .email-modal-wrapper-field{
    width:100%;
    margin-bottom: 10px;
  }
  .email-modal-wrapper-table-wrapper {
    height: 250px;
    margin-bottom: 10px;
    .email-modal-wrapper-table-wrapper-table {
      height:100%;
      overflow-y: auto;

      .email-modal-wrapper-table-wrapper-table-operation-ref-wrapper {
        background-color: #1079FC;
        box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        padding: 4px;
        margin-top: -2px;
        margin-bottom: -2px;
        font-weight: bold;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
        margin-right: 4px;
      }
      .email-modal-wrapper-table-wrapper-table-multiple-operations-ref-wrapper{
        display:flex;
      }

      th {
        font-weight: bold;
      }
      td{
        padding:0 16px;
      }

      //tr:hover {
      //  background-color: rgba(0, 0, 0, 0.12);
      //}
    }

  }
  .email-modal-wrapper-loader{
    z-index:12;
    position:absolute;
    top:300px;
    right:50%;
    transform: translateX(50%);
  }
}


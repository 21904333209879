.article-details-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;

  .article-details-wrapper-header {
    padding: 10px;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;


    .article-details-wrapper-header-buttons {
    }
  }

  .finish-details-wrapper-pdv {
    margin-bottom: 10px;
    padding: 10px;

    .finish-details-wrapper-pdv-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .finish-details-wrapper-pdv-title {
        font-size: 20px;
      }
    }
  }
}

.pdv-edit-modal-text-fields-wrapper {
  width: 600px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  .pdv-edit-modal-text-fields-wrapper-tarifs-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
}

.article-rules-graphs-wrapper{
  display:flex;
  justify-content: space-between;

  .article-rule-graph-wrapper {
    width: calc(37.5% - 10px);
    height:350px;


    .article-rule-graph-wrapper-header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      background-color: rgb(250, 250, 250);
      border-bottom: solid 1px #CCCCCC;

      .article-rule-graph-wrapper-header-title {
        font-size: 18px;
        align-self: flex-start;
      }
    }

    .article-rule-graph-wrapper-graph-container {
      height: 300px;
      padding:10px;
      .article-rule-graph-wrapper-graph-container-developed-rule{
        font-size: 24px;
        height:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div:first-of-type{
          margin-bottom: 24px;
        }
      }
    }
  }
  .articles-rules-developed-rule-wrapper{
    width:calc(20% - 5px);
    height: 300px;
  }
}

.article-details-ranges-list-wrapper {
  margin-top: 10px;
  width: 100%;
  height:calc(100% - 510px);


  .article-details-ranges-list-wrapper-content {
    height:100%;

    .article-details-ranges-list-wrapper-content-table {
      overflow: auto;
      height:100%;
      th {
        font-weight: bold;
      }

      //tr:hover {
      //  background-color: rgba(0, 0, 0, 0.12);
      //}
      .article-details-ranges-list-wrapper-developed-ranges-labels{
        display:flex;
        flex-direction: column;
      }
      .article-details-ranges-list-wrapper-developed-ranges-intervals{
        display:flex;
        flex-direction: column;
      }
    }

    .article-details-ranges-list-wrapper-content-pagination {
      display:flex;
      justify-content: flex-end;
      margin-top:10px;
    }
  }
  .article-details-ranges-list-wrapper-content-loader-wrapper{
    padding:10px;
    position:absolute;
    left:50%;
    top:50%;
    transform: translate(-50%);
  }
}

@import 'src/App';

.products-general-all-products-wrapper {
  width: 100%;

  .products-general-all-products-wrapper-header {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .products-general-all-products-wrapper-header-title {
      font-size: 24px;
    }

    .products-general-all-products-wrapper-header-counts {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      span:first-of-type {
        margin-bottom: 5px;
      }
    }

    .products-general-all-products-wrapper-header-filter-sort {
      .products-general-all-products-wrapper-header-sort-input {
        width: 300px;
      }

      .products-general-all-products-wrapper-header-filter-input {
        margin-left: 10px;
        width: 300px;
      }

      button {
        //border: solid 1px rgb(196, 196, 196);
        margin-left: 10px;
      }
    }
  }

  .products-general-all-products-wrapper-content {
    padding: 10px;

    .products-general-all-products-wrapper-content-table {
      overflow-y: auto;
      min-height: 477px;

      .products-general-all-products-wrapper-content-table-finish-buttons {
        display: flex;
        justify-content: space-between;
      }

      .products-general-all-products-wrapper-content-table-actions-buttons {
        display: flex;

        button {
          white-space: nowrap;

        }

        button:last-of-type {
          margin-left: 5px;
        }
      }

      .products-general-all-products-wrapper-content-table-operation-ref-wrapper {
        cursor: pointer;
        background-color: $primary-color;
        padding: 4px;
        margin-top: -2px;
        margin-bottom: -2px;
        font-weight: bold;
        border-radius: 4px;
        color: #FFFFFF;
        width: fit-content;
      }

      th {
        font-weight: bold;
      }

      //tr:hover {
      //  background-color: rgba(0, 0, 0, 0.12);
      //}
    }

    .products-general-all-products-wrapper-content-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .products-general-all-products-wrapper-content-loader-wrapper {
    padding: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
}

.products-create-article-wrapper {
  width: 800px;
  overflow: hidden;
  .products-create-article-wrapper-header {
    padding: 20px 24px;
    padding-bottom: 0;

    .products-create-article-wrapper-header-autofill {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }

  .products-create-article-wrapper-text-fields {
    display: flex;
    flex-direction: column;
  }

  .products-create-article-wrapper-ranges {
    overflow-y: auto;
    height:300px;

    .products-create-article-wrapper-range {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }
  }

}

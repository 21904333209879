.finish-details-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;

  .finish-details-wrapper-header {
    padding: 10px;
    height: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;


    .finish-details-wrapper-header-buttons {
    }
  }

  .finish-details-wrapper-pdv {
    margin-bottom: 10px;
    padding: 10px;

    .finish-details-wrapper-pdv-header {
      display:flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .finish-details-wrapper-pdv-title {
        font-size: 20px;
      }
    }
  }
}

.pdv-edit-modal-text-fields-wrapper{
  width:600px;
  margin-top:10px;
  display:flex;
  flex-direction: column;
  .pdv-edit-modal-text-fields-wrapper-tarifs-wrapper{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
}